<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">
            <i v-if="$route.params.config_menu_type == 'Resources'" class="fa fa-bookmark" style="font-size: 24px; margin-right: 5px; color: var(--iq-primary);"></i>
            <i v-else class="fa fa-solid fa-cog" style="font-size: 24px; margin-right: 3px; color: var(--iq-primary);"></i>
            {{config_menu_type}}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button v-if="userData.user_role=='USERROLE11111'" variant="primary" @click="configMenuAdd">{{cvbtnAddNew}}</b-button>
          </template>
          <template v-slot:body>
            <b-row class="row w-100">
              <!-- Frontend Search -->
              <!-- <b-col sm="4" md="4" lg="4" xl="4" class="mb-3">
                <b-form-input
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col> -->
              <!-- Frontend Search -->

              <!-- Backend Search -->
              <b-col class="col-12 col-sm-8 col-md-5 col-lg-4 mb-2">
                <b-form-input class="form-control"
                  @input="searchconfigMenuWithParams"
                  v-model="search_param"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>
              <!-- Backend Search -->
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="configMenuObjList && configMenuObjList.length > 0">
                  <b-row class="p-2">
                    <div v-for="(cm,index) in configMenuObjList" :key="index" class="mt-3 col-12 col-sm-6 col-md-4 col-lg-3">
                      <iq-card className="iq-card-block iq-card-stretch iq-card-height side_border">
                        <template v-slot:body>
                          <div class="iq-badges text-left" style="cursor: pointer;">
                            <h5 class="title" :title="cm.config_menu_name"  @click="goToDetails(cm.config_menu_link)">
                              <i :class=cm.config_menu_icon style="font-size: 20px; margin-right: 3px; color: var(--iq-primary);"></i>
                                {{ cm.config_menu_name }}<br>
                            </h5>
                            <div class="float-right mt-2">
                              <b-button v-if="userData.user_role=='USERROLE11111'" variant=" iq-bg-primary mr-1 mb-1" size="sm" @click="goToDetails(cm.config_menu_link)"><i class="fa fa-eye m-0"></i></b-button>
                              <b-button v-if="userData.user_role=='USERROLE11111'" variant=" iq-bg-success mr-1 mb-1" size="sm" @click="configMenuEdit('MODAL', cm)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                              <b-button v-if="userData.user_role=='USERROLE11111'" variant=" iq-bg-danger" @click="showConfigMenuDeleteDialog(cm)" size="sm"><i class="ri-delete-bin-line m-0"></i></b-button>
                            </div>
                          </div>
                        </template>
                      </iq-card>
                    </div>
                  </b-row>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelConfigMenuAdd"
      scrollable
      :title="cvAddModalHeader"
    >
      <ConfigMenuAdd :propOpenedInModal="true" @emitCloseConfigMenuAddModal="closeConfigMenuAddModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="configMenuAdd('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeConfigMenuAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelConfigMenuEdit"
      scrollable
      :title = "'Edit ' + config_menu_type"
      size="xl"
    >
      <ConfigMenuEdit :propOpenedInModal="true" :propConfigMenuObj="configMenuEditObj" @emitCloseConfigMenuEditModal="closeConfigMenuEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="configMenuEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeConfigMenuEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelConfigMenuDelete" size="xl" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>{{cvDelMessage}}</p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelConfigMenuDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="configMenuDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { ConfigMenus } from "../../../FackApi/api/ConfigMenu.js"
import ConfigMenuAdd from "./ConfigMenuAdd.vue"
import ConfigMenuEdit from "./ConfigMenuEdit.vue"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit.js"
import userPermission from "../../../Utils/user_permission.js"

export default {
  name: "ConfigMenuList",
  components: {
    ConfigMenuAdd,
    ConfigMenuEdit
  },
  data () {
    return {
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvAddModalHeader: "Add Configurations",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Configurations",
      showModelConfigMenuAdd: false,
      showModelConfigMenuEdit: false,
      showModelConfigMenuDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },

        { label: "config menu name", key: "config_menu_name", class: "text-left align-text-top", sortable: true },
        { label: "config menu icon", key: "config_menu_icon", class: "text-left align-text-top", sortable: true },
        { label: "config menu link", key: "config_menu_link", class: "text-left align-text-top", sortable: true },
        { label: "config menu type", key: "config_menu_type", class: "text-left align-text-top", sortable: true },
        { label: "Created On ", key: "created_on", class: "text-left align-text-top", sortable: true },
        { label: "Action ", key: "action", class: "text-left align-text-top", sortable: false }
      ],
      configMenuObjList: [],
      configMenuEditObj: null,
      filter: null,
      totalRows: 1,
      delObj: null,
      whereFilter: {
      },
      search_param: "",
      config_menu_type: ""
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    dateFilter () {
      return { ...this.$store.getters["DateFilter/selectedDateState"] }
    }
  },
  watch: {
    "$route.path": function (newPath, oldPath) {
      this.refreshPage = newPath
      this.loadRequiredData()
    }
  },
  beforeMount () {
    this.config_menu_type = this.$route.params.config_menu_type
    // Auth Access for Current User Role
    // if (!this.userPermission("config_menu_list")) {
    //   this.$router.push("/home")
    // }
  },
  async mounted () {
    socialvue.index()
    this.configMenuList()
  },
  methods: {
    /**
     * loadRequiredData
     */
    loadRequiredData () {
      socialvue.index()
      this.config_menu_type = this.$route.params.config_menu_type
      this.configMenuList()
    },
    /**
     * userPermission
     */
    userPermission (actionName = null) {
      return userPermission(this, this.userData, actionName)
    },
    /**
     * goToDetails
     */
    goToDetails (link) {
      this.$router.push(`${link}`)
    },
    /**
     * searchconfigMenuWithParams
     */
    async searchconfigMenuWithParams () {
      this.configMenuList()
    },
    /**
     * configMenuList
     */
    async configMenuList (loadViaLoadMore = false) {
      try {
        this.whereFilter.dataPerPage = this.dataPerPage
        this.whereFilter.search_param = this.search_param
        this.whereFilter.config_menu_type = this.config_menu_type
        let configMenuListResp = await ConfigMenus.configMenuList(this, this.whereFilter)
        if (configMenuListResp.resp_status) {
          if (this.whereFilter.search_param.length >= 0 && !loadViaLoadMore) {
            this.configMenuObjList = [ ...configMenuListResp.resp_data.data ]
          }
          else {
            this.configMenuObjList = [ ...this.configMenuObjList, ...configMenuListResp.resp_data.data ]
          }
          // Adding the serial numbers:
          this.configMenuObjList.forEach((s, index) => { s.id = index + 1 })

          // Filter out the config menu items based on user's role
          this.configMenuObjList = this.configMenuObjList.filter((cfgMenu) => {
            return cfgMenu.config_user_role && cfgMenu.config_user_role.split(",").find(role => role == this.userData.user_role)
          })

          this.totalRows = this.configMenuObjList.length
        }
        else {
          this.toastMsg = configMenuListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at configMenuList() and Exception:", err)
      }
    },
    /**
     * configMenuAdd
     */
    configMenuAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("../config_menu_add")
        }
        else {
          this.showModelConfigMenuAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at configMenuAdd() and Exception:", err.message)
      }
    },
    /**
     * configMenuEdit
     */
    configMenuEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("../config_menu_edit/" + this.configMenuEditObj.acm_id)
        }
        else {
          this.configMenuEditObj = item
          this.showModelConfigMenuEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at configMenuEdit() and Exception:", err.message)
      }
    },
    /**
     * showConfigMenuDeleteDialog
     */
    showConfigMenuDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelConfigMenuDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showConfigMenuDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * configMenuDelete
     */
    async configMenuDelete () {
      try {
        let configMenuDelResp = await ConfigMenus.configMenuDelete(this, this.delObj.acm_id)
        await ApiResponse.responseMessageDisplay(this, configMenuDelResp)

        if (configMenuDelResp && !configMenuDelResp) {
          this.showModelConfigMenuDelete = false
          return false
        }

        let index = this.configMenuObjList.indexOf(this.delObj)
        this.configMenuObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelConfigMenuDelete = false
      }
      catch (err) {
        console.error("Exception occurred at configMenuDelete() and Exception:", err.message)
      }
    },
    /**
     * closeConfigMenuAddModal
     */
    closeConfigMenuAddModal (configMenuAddData) {
      try {
        if (configMenuAddData) {
          if (this.configMenuObjList && this.configMenuObjList.length >= 1) {
            let configMenuObjLength = this.configMenuObjList.length
            let lastId = this.configMenuObjList[configMenuObjLength - 1]["id"]
            configMenuAddData.id = lastId + 1
          }
          else {
            this.configMenuObjList = []
            configMenuAddData.id = 11111
          }

          configMenuAddData.created_on = moment()
          this.configMenuObjList.unshift(configMenuAddData)
          this.totalRows = this.totalRows + 1
        }

        this.showModelConfigMenuAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closeconfigMenuAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeConfigMenuEditModal
     */
    closeConfigMenuEditModal () {
      try {
        this.showModelConfigMenuEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeConfigMenuEditModal() and Exception:", err.message)
      }
    }
  }
}
</script>
